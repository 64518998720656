import { Resource as ResourceType } from '../types';

export const document: ResourceType = {
  name: 'documents',
  label: 'resource.documents.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.documents.field.id.label',
      type: 'text',
    },
    {
      key: 'name',
      label: 'resource.documents.field.name.label',
      type: 'text',
    },
    {
      key: 'description',
      label: 'resource.documents.field.description.label',
      type: 'text',
      multiline: true,
    },
    {
      key: 'kind_name',
      label: 'resource.documents.field.kind.label',
      type: 'tag',
    },
    {
      key: 'validity_period',
      label: 'resource.documents.field.validity_period.label',
      type: 'select',
      choices: [
        {
          id: 'unlimited',
          name: 'resource.documents.field.validity_period.choices.unlimited.label',
        },
        {
          id: 'duration_from_file_date',
          name: 'resource.documents.field.validity_period.choices.duration_from_file_date.label',
        },
      ],
    },
    {
      key: 'duration',
      label: 'resource.documents.field.duration.label',
      type: 'number',
    },
    {
      key: 'user_kind',
      label: 'resource.documents.field.user_kind.label',
      type: 'select',
      choices: [
        {
          id: 'expert',
          name: 'resource.documents.field.user_kind.choices.expert.label',
        },
        {
          id: 'client',
          name: 'resource.documents.field.user_kind.choices.client.label',
        },
        {
          id: 'mixed',
          name: 'resource.documents.field.user_kind.choices.mixed.label',
        },
      ],
    },
    {
      key: 'condition',
      label: 'resource.documents.field.condition.label',
      type: 'select',
      choices: [
        {
          id: 'optional',
          name: 'resource.documents.field.condition.choices.optional.label',
        },
        {
          id: 'mandatory_to_apply',
          name: 'resource.documents.field.condition.choices.mandatory_to_apply.label',
        },
        {
          id: 'mandatory_to_send_proposal',
          name: 'resource.documents.field.condition.choices.mandatory_to_send_proposal.label',
        },
      ],
    },
    {
      key: 'client_condition',
      label: 'resource.documents.field.client_condition.label',
      type: 'select',
      choices: [
        {
          id: 'optional',
          name: 'resource.documents.field.client_condition.choices.optional.label',
        },
        {
          id: 'mandatory_to_publish_mission',
          name: 'resource.documents.field.client_condition.choices.mandatory_to_publish_mission.label',
        },
      ],
    },
    {
      key: 'sectors',
      label: 'resource.companies.field.sectors.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'leaders_and_shareholders',
          name: 'resource.companies.field.sectors.choices.leaders_and_shareholders.label',
        },
        {
          id: 'investment_funds',
          name: 'resource.companies.field.sectors.choices.investment_funds.label',
        },
        {
          id: 'listed_companies',
          name: 'resource.companies.field.sectors.choices.listed_companies.label',
        },
        {
          id: 'startups_and_innovation',
          name: 'resource.companies.field.sectors.choices.startups_and_innovation.label',
        },
        {
          id: 'digital_and_e_commerce',
          name: 'resource.companies.field.sectors.choices.digital_and_e_commerce.label',
        },
        {
          id: 'media_and_communication',
          name: 'resource.companies.field.sectors.choices.media_and_communication.label',
        },
        {
          id: 'franchise_distribution_commerce',
          name: 'resource.companies.field.sectors.choices.franchise_distribution_commerce.label',
        },
        {
          id: 'entertainment',
          name: 'resource.companies.field.sectors.choices.entertainment.label',
        },
        {
          id: 'hospitality_and_leisure_restaurant',
          name: 'resource.companies.field.sectors.choices.hospitality_and_leisure_restaurant.label',
        },
        {
          id: 'banking_insurance_mutual',
          name: 'resource.companies.field.sectors.choices.banking_insurance_mutual.label',
        },
        {
          id: 'textile_and_luxury',
          name: 'resource.companies.field.sectors.choices.textile_and_luxury.label',
        },
        {
          id: 'real_estate_and_development',
          name: 'resource.companies.field.sectors.choices.real_estate_and_development.label',
        },
        {
          id: 'industrial',
          name: 'resource.companies.field.sectors.choices.industrial.label',
        },
        {
          id: 'institutional',
          name: 'resource.companies.field.sectors.choices.institutional.label',
        },
        {
          id: 'non_profit_organizations_and_social_economy',
          name: 'resource.companies.field.sectors.choices.non_profit_organizations_and_social_economy.label',
        },
        {
          id: 'energy_and_environment',
          name: 'resource.companies.field.sectors.choices.energy_and_environment.label',
        },
        {
          id: 'liberal_professions',
          name: 'resource.companies.field.sectors.choices.liberal_professions.label',
        },
        {
          id: 'artisans_and_trades_people',
          name: 'resource.companies.field.sectors.choices.artisans_and_trades_people.label',
        },
        {
          id: 'institutional_and_public_figures',
          name: 'resource.companies.field.sectors.choices.institutional_and_public_figures.label',
        },
        {
          id: 'individuals_and_families',
          name: 'resource.companies.field.sectors.choices.individuals_and_families.label',
        },
        {
          id: 'multisectoral_or_no_sectorial_expertise',
          name: 'resource.companies.field.sectors.choices.multisectoral_or_no_sectorial_expertise.label',
        },
      ],
    },
    {
      key: 'company_kinds',
      label: 'resource.missions.field.company_kinds.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'big_4_and_similar',
          name: 'resource.companies.field.kind.choices.big_4_and_similar.label',
        },
        {
          id: 'anglo_saxon_firms',
          name: 'resource.companies.field.kind.choices.anglo_saxon_firms.label',
        },
        {
          id: 'french_international_business_law_firm',
          name: 'resource.companies.field.kind.choices.french_international_business_law_firm.label',
        },
        {
          id: 'full_service_law_firm_over_50',
          name: 'resource.companies.field.kind.choices.full_service_law_firm_over_50.label',
        },
        {
          id: 'full_service_law_firm_under_50',
          name: 'resource.companies.field.kind.choices.full_service_law_firm_under_50.label',
        },
        {
          id: 'specialized_law_firm',
          name: 'resource.companies.field.kind.choices.specialized_law_firm.label',
        },
        {
          id: 'general_practice_law_firm',
          name: 'resource.companies.field.kind.choices.general_practice_law_firm.label',
        },
        {
          id: 'council_of_state_law_firm',
          name: 'resource.companies.field.kind.choices.council_of_state_law_firm.label',
        },
        {
          id: 'individual_law_practitioner',
          name: 'resource.companies.field.kind.choices.individual_law_practitioner.label',
        },
        {
          id: 'legal_department_of_a_french_company',
          name: 'resource.companies.field.kind.choices.legal_department_of_a_french_company.label',
        },
        {
          id: 'legal_department_of_an_international_company',
          name: 'resource.companies.field.kind.choices.legal_department_of_an_international_company.label',
        },
        {
          id: 'freelance',
          name: 'resource.companies.field.kind.choices.freelance.label',
        },
        {
          id: 'unknown',
          name: 'resource.companies.field.kind.choices.unknown.label',
        },
        {
          id: 'recruitment_candidate',
          name: 'resource.companies.field.kind.choices.recruitment_candidate.label',
        },
      ],
    },
    {
      key: 'contract_types',
      label: 'resource.companies.field.contract_types.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.fulltime_contract.label',
        },
        {
          id: 'missions',
          name: 'resource.companies.field.contract_types.choices.missions.label',
        },
        {
          id: 'missions_or_fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.missions_or_fulltime_contract.label',
        },
      ],
    },
    {
      key: 'solo_collective',
      label: 'resource.documents.field.solo_collective.label',
      type: 'select',
      choices: [
        {
          id: '',
          name: 'resource.documents.field.solo_collective.choices.empty.label',
        },
        {
          id: 'solo',
          name: 'resource.documents.field.solo_collective.choices.solo.label',
        },
        {
          id: 'collective',
          name: 'resource.documents.field.solo_collective.choices.collective.label',
        },
      ],
    },
    {
      key: 'company_ids',
      label: 'resource.documents.field.company_ids.label',
      type: 'referenceArray',
      reference: 'companies',
    },
    {
      key: 'company_client_ids',
      label: 'resource.documents.field.company_ids.label',
      type: 'referenceArray',
      reference: 'companies',
    },
    {
      key: 'company_expert_ids',
      label: 'resource.documents.field.company_ids.label',
      type: 'referenceArray',
      reference: 'companies',
    },
    {
      key: 'company_mixed_ids',
      label: 'resource.documents.field.company_ids.label',
      type: 'referenceArray',
      reference: 'companies',
    },
    {
      key: 'locations',
      label: 'resource.documents.field.locations.label',
      type: 'location',
    },
    {
      key: 'attachments',
      label: 'resource.documents.field.attachments.label',
      type: 'file',
    },
    {
      key: 'sub_categories',
      label: 'resource.documents.field.sub_categories.label',
      type: 'referenceArray',
      reference: 'sub_categories',
    },
    {
      key: 'sub_categories_id',
      label: 'resource.companies.field.sub_categories_name.label',
      type: 'referenceArray',
      reference: 'sub_categories',
    },
    {
      key: 'created_at',
      label: 'resource.companies.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.companies.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'status',
      label: 'resource.documents.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'online',
          name: 'resource.documents.field.status.choices.online.label',
        },
        {
          id: 'archived',
          name: 'resource.documents.field.status.choices.archived.label',
        },
      ],
    },
    {
      key: 'post_signup_display',
      label: 'resource.documents.field.post_signup_display.label',
      type: 'boolean',
    },
    {
      key: 'mission_display',
      label: 'resource.documents.field.mission_display.label',
      type: 'boolean',
    },
  ],
  pages: {
    list: {
      hasCreateButton: true,
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'condition',
        },
      ],
      fields: [
        {
          source: 'name',
        },
        {
          source: 'kind_name',
        },
        {
          source: 'condition',
        },
        {
          source: 'client_condition',
        },
        {
          source: 'status',
        },
      ],
      bulkActionButtons: [],
    },
    create: {
      left: {
        tabs: [
          {
            key: 'documents',
            title: 'resource.documents.tab.title',
            sections: [
              {
                key: 'users',
                title: 'resource.documents.section.create.title',
                layout: [
                  ['name'],
                  ['description'],
                  ['attachments'],
                  ['kind_name'],
                  ['validity_period'],
                  ['duration'],
                  ['user_kind'],
                  ['condition'],
                  ['client_condition'],
                ],
                inputs: {
                  name: {
                    validators: [{ key: 'required' }],
                  },
                  description: {},
                  attachments: {},
                  kind_name: {
                    validators: [{ key: 'required' }],
                  },
                  validity_period: {
                    validators: [{ key: 'required' }],
                  },
                  duration: {
                    validators: [
                      {
                        key: 'required',
                        rules: [
                          {
                            property: 'validity_period',
                            op: 'eq',
                            value: 'duration_from_file_date',
                          },
                        ],
                      },
                    ],
                  },
                  user_kind: {
                    validators: [{ key: 'required' }],
                  },
                  condition: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                  client_condition: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'target',
                title: 'resource.documents.section.target.create.title',
                layout: [
                  ['locations'],
                  ['sectors'],
                  ['sub_category'],
                  ['company_kinds'],
                  ['contract_types'],
                  ['solo_collective'],
                  ['company_expert_ids'],
                  ['company_client_ids'],
                  ['company_mixed_ids'],
                ],
                inputs: {
                  locations: {},
                  sectors: {},
                  sub_category: {
                    source: 'sub_categories_id',
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                    validators: [
                      {
                        key: 'maxArrayLength',
                        value: 5,
                        message:
                          'resource.validators.sub_category_length.message',
                      },
                    ],
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'sub_categories_name_phrase',
                      options: {
                        perPage: 184,
                        allowEmpty: false,
                      },
                      extensions: [
                        {
                          key: 'sort',
                          params: {
                            type: 'alphabetic',
                            mode: 'asc',
                          },
                        },
                        {
                          key: 'catLengthValidation',
                          params: {
                            max: 2,
                            message:
                              'resource.validators.category_length.message',
                          },
                        },
                      ],
                    },
                  },
                  company_kinds: {},
                  contract_types: {
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  solo_collective: {},
                  company_expert_ids: {
                    filter: { user_kind: 'expert' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'mixed',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    enableGetChoices: true,
                    child: {
                      type: 'autocompleteArray',
                      extensions: [
                        {
                          key: 'concatenateParams',
                          params: {
                            first: 'name_or_leader_full_name',
                            second: 'leader_email',
                          },
                        },
                      ],
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                  company_client_ids: {
                    filter: { user_kind: 'client', administrator: 'false' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'mixed',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    enableGetChoices: true,
                    child: {
                      type: 'autocompleteArray',
                      extensions: [
                        {
                          key: 'concatenateParams',
                          params: {
                            first: 'name_or_leader_full_name',
                            second: 'leader_email',
                          },
                        },
                      ],
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                  company_mixed_ids: {
                    filter: { administrator: 'false' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    enableGetChoices: true,
                    child: {
                      type: 'autocompleteArray',
                      extensions: [
                        {
                          key: 'concatenateParams',
                          params: {
                            first: 'name_or_leader_full_name',
                            second: 'leader_email',
                          },
                        },
                      ],
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    },
    edit: {
      singleRedirect: {
        page: 'edit',
        basePath: '/documents',
        recordId: 'id',
      },
      actions: [
        {
          name: 'archive',
          type: 'update',
          label: 'resource.documents.bulk_action_buttons.archive.label',
          values: { archived: true },
          withConfirmation: true,
          confirmation: {
            content: 'modal.archive.documents.content',
          },
          disabled: [{ property: 'status', op: 'eq', value: 'archived' }],
        },
        {
          name: 'unarchive',
          type: 'update',
          label: 'resource.documents.bulk_action_buttons.online.label',
          values: { archived: false },
          withConfirmation: true,
          confirmation: {
            content: 'modal.unarchive.documents.content',
          },
          disabled: [{ property: 'status', op: 'eq', value: 'online' }],
        },
      ],
      left: {
        tabs: [
          {
            key: 'document',
            title: 'resource.document.tab.information.title',
            sections: [
              {
                key: 'document',
                title: 'resource.document.section.information.title',
                layout: [
                  ['name'],
                  ['description'],
                  ['attachments'],
                  ['kind_name'],
                  ['validity_period'],
                  ['duration'],
                  ['user_kind'],
                  ['condition'],
                  ['client_condition'],
                  ['post_signup_display'],
                  ['mission_display'],
                ],
                inputs: {
                  name: {
                    validators: [{ key: 'required' }],
                  },
                  description: {},
                  attachments: {},
                  kind_name: {
                    validators: [{ key: 'required' }],
                  },
                  validity_period: {
                    validators: [{ key: 'required' }],
                  },
                  duration: {
                    validators: [
                      {
                        key: 'required',
                        rules: [
                          {
                            property: 'validity_period',
                            op: 'eq',
                            value: 'duration_from_file_date',
                          },
                        ],
                      },
                    ],
                  },
                  user_kind: {
                    validators: [{ key: 'required' }],
                  },
                  condition: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                  client_condition: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                  post_signup_display: {
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'post_signup_display',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                  },
                  mission_display: {
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'mission_display',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'document_target',
                title: 'resource.document_target.section.information.title',
                layout: [
                  ['locations'],
                  ['sectors'],
                  ['sub_category'],
                  ['company_kinds'],
                  ['contract_types'],
                  ['solo_collective'],
                  ['company_expert_ids'],
                  ['company_client_ids'],
                  ['company_mixed_ids'],
                ],
                inputs: {
                  locations: {},
                  sectors: {},
                  sub_category: {
                    source: 'sub_categories_id',
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'sub_categories_name_phrase',
                      options: {
                        perPage: 184,
                        allowEmpty: false,
                      },
                      extensions: [
                        {
                          key: 'sort',
                          params: {
                            type: 'alphabetic',
                            mode: 'asc',
                          },
                        },
                      ],
                    },
                  },
                  company_kinds: {},
                  contract_types: {
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  solo_collective: {},
                  company_expert_ids: {
                    filter: { user_kind: 'expert' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'mixed',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    enableGetChoices: true,
                    child: {
                      type: 'autocompleteArray',
                      extensions: [
                        {
                          key: 'concatenateParams',
                          params: {
                            first: 'name_or_leader_full_name',
                            second: 'leader_email',
                          },
                        },
                      ],
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                  company_client_ids: {
                    filter: { user_kind: 'client', administrator: 'false' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'mixed',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    enableGetChoices: true,
                    child: {
                      type: 'autocompleteArray',
                      extensions: [
                        {
                          key: 'concatenateParams',
                          params: {
                            first: 'name_or_leader_full_name',
                            second: 'leader_email',
                          },
                        },
                      ],
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                  company_mixed_ids: {
                    filter: { administrator: 'false' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    enableGetChoices: true,
                    child: {
                      type: 'autocompleteArray',
                      extensions: [
                        {
                          key: 'concatenateParams',
                          params: {
                            first: 'name_or_leader_full_name',
                            second: 'leader_email',
                          },
                        },
                      ],
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                },
                fields: {},
              },
            ],
          },
          {
            key: 'experts',
            title: 'resource.document.tab.experts.title',
            sections: [
              {
                key: 'experts',
                title: 'resource.document.section.information.experts.title',
                layout: [],
                inputs: {},
                list: {
                  hasEditButton: false,
                  actions: [],
                  resource_to_fetch: 'documents_companies',
                  filter_resource: 'document',
                  filter_attribute: 'id',
                  fields: [
                    {
                      source: 'company_leader_id',
                      child: {
                        source: 'users.full_name',
                      },
                    },
                    {
                      source: 'company_leader_company_name',
                    },
                    {
                      source: 'company_leader_job_name',
                    },
                    {
                      source: 'status',
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.document.section.details.title',
              layout: [['id'], ['created_at', 'updated_at'], ['status']],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
                status: {
                  disabled: true,
                },
              },
            },
          ],
        },
      ],
    },
  },
};
